export const accountsData = [
  {
    number: '96 1002 0000 4090 0112 3300 4000',
    balance: '36 146.78',
    available: '25 553.13',
    details: {
      status: 'Owner',
      opened: '21.07.2011',
      percentage: '0.00%',
      plan: 'Sensible Account',
      currency: 'USD',
      blocked: '46.99',
      oneClick: 'PayU Express Service'
    }
  },
  {
    number: '93 1005 0000 4090 0112 3300 4000',
    balance: '326 146.78',
    available: '25 553.13',
    details: {
      status: 'Owner',
      opened: '21.07.2020',
      percentage: '5.00%',
      plan: 'Sensible Account',
      currency: 'USD',
      blocked: '16.99',
      oneClick: 'PayU Express Service'
    }
  },
  {
    number: '13 1005 3000 4095 0112 3303 4000',
    balance: '6 146.78',
    available: '5 553.13',
    details: {
      status: 'Owner',
      opened: '5.07.2020',
      percentage: '15.00%',
      plan: 'Sensible Account',
      currency: 'USD',
      blocked: '233.99',
      oneClick: 'PayU Express Service'
    }
  }
]