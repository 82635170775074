import styled from 'styled-components'

export const StyledTabs = styled.div`
  margin-top: 40px;
  width: 100%;
  margin-right: 28px;
`

export const StyledTabsContent = styled.div`
  width: 100%;
`

export const StyledSingleTab = styled.div`
  display: ${({ isActive }) => isActive ? 'block' : 'none' };
`