import React from "react"
import { Head } from './../global/head'
import SEO from "../global/seo"
import { DashboardLayout } from './../layouts/DashboardLayout'
import { Tabs } from './../components/Tabs'
import { AccountsList } from './../components/AccountsList'

const Accounts = ({ location }) => (
  <>
    <Head />
    <SEO title="Dashboard" />
    <DashboardLayout pathname={location.pathname}>
      <Tabs 
        options={[
          'Accounts list',
          'Accounts history',
          'Bank Statements',
          'Transfer salary',
        ]}
        components={[
          AccountsList,
          AccountsList,
          AccountsList,
          AccountsList
        ]}
      />
    </DashboardLayout>
  </>
)

export default Accounts
