import React, { useState } from 'react'
import card1 from './../../../images/card1.svg'
import balanceIcon from './../../../images/icons/balance.svg'
import dollarblack from './../../../images/icons/dollar-black.svg'
import timeline from './../../../images/icons/timeline-black.svg'
import more from './../../../images/icons/more.svg'
import expand from './../../../images/icons/expand.svg'
import { SlideInAnimation } from './../../SlideInAnimation';

import {
  AccountSingleDetails
} from './details'

import {
  StyledSingle,
  StyledAccountMain,
  StyledCard,
  StyledData,
  StyledCardInfos,
  StyledCardInfo,
  StyledCardAction,
  StyledCardActions
} from './../styled'

export const AccountSingle = ({ number, balance, available, details, delay }) => {
  const [isExpanded, setExpanded] = useState(false)

  return (
    <SlideInAnimation delay={delay}>
      <StyledSingle>
        <StyledAccountMain>
          <StyledCard>
            <img src={card1} alt="card" />
            <StyledData>
              Savings account
              <span>{number}</span>
            </StyledData>
          </StyledCard>
          <StyledCardInfos>
            <StyledCardInfo>
              <img src={balanceIcon} alt="card" />
              <StyledData>
                Balance
                <span>${balance}</span>
              </StyledData>
            </StyledCardInfo>
            <StyledCardInfo>
              <img src={dollarblack} alt="card" />
              <StyledData>
                Available
                <span>${available}</span>
              </StyledData>
            </StyledCardInfo>
          </StyledCardInfos>
          <StyledCardActions>
            <StyledCardAction>
              <img src={timeline} alt="card" />
            </StyledCardAction>
            <StyledCardAction>
              <img src={more} alt="card" />
            </StyledCardAction>
            <StyledCardAction isExpanded={isExpanded} onClick={() => setExpanded(!isExpanded)}>
              <img src={expand} alt="card" />
            </StyledCardAction>
          </StyledCardActions>
        </StyledAccountMain>
        <AccountSingleDetails 
          isExpanded={isExpanded}
          {...details} 
        />
      </StyledSingle>
    </SlideInAnimation>
  )
}
