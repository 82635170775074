import React from 'react'
import { AccountSingle } from './single'
import { accountsData } from './../../content/accounts'

import { StyledAccountsList } from './styled'

export const AccountsList = () => {
  return (
    <StyledAccountsList>
      {
        accountsData.length && (
          accountsData.map((item, index) => (
            <AccountSingle
              key={index} 
              number={item.number}
              balance={item.balance}
              available={item.available}
              details={item.details}
              delay={index / 4}
            />
          ))
        )
      }
    </StyledAccountsList>
  )
}
