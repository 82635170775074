import React from 'react'

import {
  StyledTabsSlider,
  StyledTab
} from './styled'

export const TabsToggle = ({ options, activeTab, changeTab }) => {
  return (
    <StyledTabsSlider>
      {
        options.length && (
          options.map((item, index) => (
            <StyledTab key={index} isActive={activeTab === index}>
              <button onClick={() => changeTab(index)}>
                {item}
              </button>
            </StyledTab>
          ))
        )
      }
    </StyledTabsSlider>
  )
}
