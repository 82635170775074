import styled from 'styled-components'
import { Paragraph5 } from './../../global/typography'
import { totalCenter } from './../../global/mixins'

export const StyledAccountsList = styled.ul`
  margin-top: 40px;
`

export const StyledAccountMain = styled.div`
  padding: 25px;
  width: 100%;
  display: flex;
  box-shadow: ${({ theme }) => theme.colors.boxShadow };
  flex-direction: column;

  @media ${({ theme }) => theme.bp.md } {
    padding: 32px 28px;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const StyledCard = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;

  @media ${({ theme }) => theme.bp.md } {
    width: 40%;
    padding: 0;
    flex-direction: row;
  }

  img {
    padding: 0 0 28px 0;

    @media ${({ theme }) => theme.bp.md } {
      margin-right: 15px;
      padding: 0;
    }
  }
`

export const StyledData = styled.div`
  ${Paragraph5};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.silvergray };
  font-weight: 600;
  letter-spacing: 0.08rem;
  
  span {
    display: block;
    color: ${({ theme }) => theme.colors.black3 };
    text-transform: none;
  }
`

export const StyledCardInfos = styled.div`
  display: flex;
  width: 100%;

  @media ${({ theme }) => theme.bp.md } {
    width: 40%;
    padding: 0;
  }
`

export const StyledCardInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media ${({ theme }) => theme.bp.md } {
    width: 50%;
  }

  img {
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.gray6 };
    border-radius: ${({ theme }) => theme.radius.medium };
    margin-right: 12px;

    @media ${({ theme }) => theme.bp.md} {
      display: block;
    }
  }

  &:last-of-type {
    padding: 0;
  }
`

export const StyledCardActions = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 25px;
  top: 25px;

  @media ${({ theme }) => theme.bp.md } {
    position: static;
  }
`


export const StyledCardAction = styled.button`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.gray6 };
  border-radius: ${({ theme }) => theme.radius.medium };
  margin-right: 12px;
  ${totalCenter};
  min-height: 40px;
  min-width: 40px;

  img {
    transform: ${({ isExpanded }) => isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
  }

  &:last-of-type {
    @media ${({ theme }) => theme.bp.md} {
      margin-left: 30px;
    }
  }
`

export const StyledDetails = styled.div`
  display: ${({ isExpanded }) => isExpanded ? 'flex' : 'none' };
  padding: 27px;
  border-top: 1px solid ${({ theme }) => theme.colors.asideBorder};
  box-shadow: ${({ theme }) => theme.colors.boxShadow };
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white };
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media ${({ theme }) => theme.bp.md } {
    padding: 27px 43px;
  }

  ${StyledData} {
    font-size: 1rem;
    padding-bottom: 25px;
    flex: 50%;

    &:last-of-type {
      padding: 0;
    }

    @media ${({ theme }) => theme.bp.md } {
      padding-bottom: 0px;
      flex: 33%;
      flex: auto;
    }

    span {
      font-size: 1.6rem;
    }
  }
`

export const StyledToggle = styled.button`
  position: relative;
  width: 42px;
  height: 24px;
  background-color: ${({ isCardSelected }) => isCardSelected ? '#47BA67' : '#C8D1DB'};
  border-radius: ${({ theme }) => theme.radius.xlarge };
  margin-top: 10px;
  transition: ${({ theme }) => theme.transitions.fast };

  &:before {
    position: absolute;
    content: '';
    left: 0;
    transform: ${({ isCardSelected }) => isCardSelected ? 'translateX(4px)' : 'translateX(22px)'};
    top: 4px;
    width: 16px;
    height: 16px;
    border-radius: ${({ theme }) => theme.radius.round };
    background-color: ${({ theme }) => theme.colors.white };
    transition: ${({ theme }) => theme.transitions.fast };
  }
`

export const StyledSingle = styled.li`
  position: relative;
  margin-bottom: 25px;
  border-radius: 4px;

  @media ${({ theme }) => theme.bp.md } {
    border: 1px solid #E2E8F0;
  }
`