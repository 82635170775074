import React, { useState } from 'react'
import { TabsToggle } from './../TabsToggle'

import {
  StyledTabs,
  StyledTabsContent,
  StyledSingleTab
} from './styled'

export const Tabs = ({ options, components }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <StyledTabs>
      <TabsToggle 
        options={options} 
        activeTab={activeTab}
        changeTab={setActiveTab} 
      />
      <StyledTabsContent>
        {
          components.length && (
            components.map((Component, index) => (
              <StyledSingleTab key={index} isActive={index === activeTab}>
                <Component />
              </StyledSingleTab>
            ))
          )
        }
      </StyledTabsContent>
    </StyledTabs>
  )
}
