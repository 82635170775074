import styled from 'styled-components'
import { Paragraph2 } from './../../global/typography'

export const StyledTabsSlider = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  justify-content: flex-start;
  white-space: nowrap;
  margin-bottom: 20px;
  padding-bottom: 10px;

  @media all and (min-width: 550px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const StyledTab = styled.li`
  ${Paragraph2};
  letter-spacing: 0.02rem;
  margin-right: 50px;

  button {
    color: ${({ theme, isActive }) => isActive ? theme.colors.black2 : theme.colors.silvergray };
    font-weight: 600;
  }

  &:last-of-type {
    margin: 0;
  }
`