import React, { useState } from 'react'

import {
  StyledDetails,
  StyledToggle,
  StyledData
} from './../styled'

export const AccountSingleDetails = ({ status, opened, percentage, plan, currency, blocked, oneClick, isExpanded }) => {
  const [isCardSelected, selectCard] = useState(false);

  return (
    <StyledDetails isExpanded={isExpanded}>
      <StyledData>
        Ownership Status
        <span>{status}</span>
      </StyledData>
      <StyledData>
        Open date
        <span>{opened}</span>
      </StyledData>
      <StyledData>
        Percentage
        <span>{percentage}</span>
      </StyledData>
      <StyledData>
        plan
        <span>{plan}</span>
      </StyledData>
      <StyledData>
        currency
        <span>{currency}</span>
      </StyledData>
      <StyledData>
        Blocked
        <span>${blocked}</span>
      </StyledData>
      <StyledData hasBorder={true}>
        Free service - one click purchase
        <span>{oneClick}</span>
      </StyledData>
      <StyledToggle isCardSelected={isCardSelected} onClick={() => selectCard(!isCardSelected) } />
    </StyledDetails>
  )
}
